import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/code-climate-velocity-meta.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import PatternsIcon from '../../assets/images/product/blurb-icons/anti-patterns.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import ZoomIcon from '../../assets/images/product/blurb-icons/zoom.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/org-insights-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/investment-balance-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/enterprise/issue-pop-up.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const SwarmiaVsCCV = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Code Climate Velocity vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Code Climate Velocity and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Code Climate Velocity vs. Swarmia</>}
        content="Code Climate Velocity is one of the most successful first-generation Git analytics tools. But if you're looking for a healthier approach to driving engineering effectiveness, Swarmia may well be a better alternative."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading="What do Swarmia and Code Climate Velocity have in common?"
        content="Both Swarmia and Code Climate Velocity surface essential engineering metrics and help software leaders gain visibility into their organization."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Engineering metrics"
            text="Track essential engineering metrics from GitHub and Jira."
            icon={PatternsIcon}
          />,
          <Blurb
            key="2"
            title="Insights for leaders"
            text="Gain visibility into the entire engineering organization."
            icon={ZoomIcon}
          />,
          <Blurb
            key="3"
            title="Enterprise ready"
            text="From team hierarchies to SSO integrations, both tools meet enterprise needs."
            icon={AllWorkIcon}
          />,
        ]}
      />
      <QuoteBlock person="pau" />
      <LeadBlock
        heading="Why modern engineering organizations choose Swarmia over Code Climate Velocity"
        content="Where Code Climate is mainly a dashboard for the engineering leadership, Swarmia is a comprehensive platform that puts your whole engineering organization on a path of continuous improvement."
      />
      <MosaicBlock
        title="Not another dashboard"
        content={
          <>
            Unlike Code Climate Velocity, Swarmia is not just a dashboard for
            the engineering leadership.
            <br />
            <br />
            In addition to delivering useful insights across GitHub and Jira,
            Swarmia allows teams to set their own targets based on what’s
            relevant and achievable for them. It does that by sending daily
            updates to teams’ Slack channels and actionable notifications to
            engineers.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
      />
      <MosaicBlock
        title="Easy to use"
        content={
          <>
            A recurring piece of feedback we hear from former Code Climate
            Velocity users is that the tool was hard to use, hard to understand,
            and didn’t work as expected.
            <br />
            <br />
            Unlike Code Climate, Swarmia is often described as a platform that’s
            easy to navigate, intuitive to use, and quick to configure. You can
            drill down into any aggregate metric to quickly understand what it
            consists of and how to make concrete changes.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="No toxic metrics"
        content={
          <>
            Like many analytics-heavy engineering productivity tools, Code
            Climate Velocity’s aim seems to be to surface as many metrics as
            humanly possible. The problem is, some of these metrics are
            nonsensical while others can create an unhealthy environment by
            tracking the activity of individual developers.
            <br />
            <br />
            In contrast, we’re extremely selective about what data we surface in
            Swarmia and only show measures that have a proven correlation with
            business performance. We focus on healthy system-, organization-,
            and team-level metrics that don’t make your engineers feel like
            they’re being spied on or unfairly compared.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <QuoteBlock person="alex2" />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Unlike Code Climate Velocity, which is difficult to set up, Swarmia combines the best of both worlds: enterprise-grade features with flexible self-service configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to export APIs, Swarmia fits the needs of large organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and roll out."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Join the high-performing teams using Swarmia today."
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/bringing-visibility-into-a-developers-work/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default SwarmiaVsCCV
